import axiosInstance from "./axiosInstance";
import { GET_PRESELECTED_RUBRICS } from "./URL";

export const getSelectedRubricsList = async (clientId, role, stageId) => {
  let endpoint = GET_PRESELECTED_RUBRICS;
  const URL =
    endpoint + `?clientId=${clientId}&role=${role}&stageId=${stageId}`;
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axiosInstance.get(URL, Config).then((res) => res.data);
};
